import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuTabs: [],
    activeTAb: '',
  },
  getters: {
  },
  mutations: {
    addMenuTabs(state, payload){
      if(state.menuTabs.findIndex((e) => e.query.title == payload.query.title) == -1 ) {
        state.menuTabs.push(payload);
      }
    },
    deleteMenuTabs(state, payload){
      state.menuTabs = state.menuTabs.filter((e) => e.name !== payload);
    },
    setActiveTAb(state, payload){
      state.activeTAb = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
