import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },
  {
    path: "/EMPC",
    // name: "EMPC",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/root/index.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/home.vue"
          ),
      },
      {
        path: "infor_overview",
        name: "infor_overview",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/infor_overview/index.vue"
          ),
      },
      {
        path: "person_info",
        name: "person_info",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/person_info/index.vue"
          ),
      },
      {
        path: "device_info",
        name: "device_info",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/device_info/index.vue"
          ),
      },
      {
        path: "material_info",
        name: "material_info",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/material_info/index.vue"
          ),
      },
      {
        path: "construction_site_news",
        name: "construction_site_news",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/construction_site_news/index.vue"
          ),
      },
      {
        path: "site_layout",
        name: "site_layout",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/site_layout/index.vue"
          ),
      },
      {
        path: "resource_plan_manage",
        name: "resource_plan_manage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/resource_plan_manage/index.vue"
          ),
      },
      {
        path: "quality_manage",
        name: "quality_manage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/quality_manage/index.vue"
          ),
      },
      {
        path: "safety_manage",
        name: "safety_manage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/safety_manage/index.vue"
          ),
      },
      {
        path: "construction_manage",
        name: "construction_manage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/construction_manage/index.vue"
          ),
      },
      {
        path: "cost_management",
        name: "cost_management",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/cost_management/index.vue"
          ),
      },
      {
        path: "3d_disclose",
        name: "3d_disclose",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/3d_disclose/index.vue"
          ),
      },
      {
        path: "component_count",
        name: "component_count",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/component_count/index.vue"
          ),
      },
      {
        path: "demand_plan",
        name: "demand_plan",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/demand_plan/index.vue"
          ),
      },
      {
        path: "design_model",
        name: "design_model",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/design_model/index.vue"
          ),
      },
      {
        path: "production_model",
        name: "production_model",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/production_model/index.vue"
          ),
      },
      {
        path: "construction_model",
        name: "construction_model",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/construction_model/index.vue"
          ),
      },
      {
        path: "drawing_document",
        name: "drawing_document",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/drawing_document/index.vue"
          ),
      },
      {
        path: "model_document",
        name: "model_document",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/model_document/index.vue"
          ),
      },
      {
        path: "engineering_document",
        name: "engineering_document",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/engineering_document/index.vue"
          ),
      },
      {
        path: "schedule_manage",
        name: "schedule_manage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/schedule_manage/index.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.name != 'home' && to.name != 'login'){
    store.commit("addMenuTabs",{...to});
  }
  next();
})
export default router;
