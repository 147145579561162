<template>
  <div id="EMPC">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
import { KeepAlive } from 'vue';
export default {
  
}
</script>

<style lang="less" scoped>
#EMPC{
  height: 100%;
}
</style>
